@import url('https://fonts.googleapis.com/css?family=Anton:100,200,300,300i,400,400i,700');

body {
  font-family: 'Anton';
  background: black;
  color: #979797;
  height: 100vh;
}

.buttonWrapper {
  text-align: right;
  position: absolute;
  top: 8px;
  right: 8px;
}

.material-icons {
  font-size: 48px;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8rem 0;
}

.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}