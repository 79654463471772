h1 {
  text-transform: uppercase;
  font-size: 6rem;
  text-align: center;
}

.currentWeather {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  align-items: center;
}

.currentTemperature {
  font-size: 10rem;
  text-align: center;
  margin: 0;
}

.currentTemperature  p {
  margin: 0
}

.currentDescription {
  padding-left: 2rem;
  font-size: 1.6rem;
  text-transform: capitalize;
}

.currentDescription__image, .currentDescription__text {
  text-align: center;
}

.currentWeather__imageWrapper {
  text-align: center;
}

.currentWeather__image {
  margin-top: 0.8rem;
  height: 8rem;
}

.forecast {
  display: flex;
  margin-top: 4rem;
}

.forecastDay {
  flex: 1;
  text-align: center;
}

.forecastDescription {
  text-transform: capitalize;
}

.forecastTemperature {
  font-size: 2.4rem;
  margin: 0;
}

.forecastImage {
  height: 80px;
}

.disclaimerWrapper {
  margin: 4rem;
}

.disclaimer {
  text-align: left;
}

@media only screen and (max-width: 725px) {
  h1 {
    font-size: 40px;
  }

  .currentWeather {
    margin-top: 6rem;
  }

  .currentTemperature {
    font-size: 100px;
  }

  .currentDescription {
    font-size: 16px;
  }
  
  .currentWeather__image {
    margin-top: 0.4rem;
    height: 4rem;
  }

  .forecast {
    margin-top: 6rem;
  }
  
  .forecastTemperature{
    font-size: 24px;
    margin: 0;
  }

  .forecastDate {
    font-size: 16px;
    margin: 0
    }

  .forecastImage {
    height: 36px;
  }
  
}

@media only screen and (max-width: 325px) {
  h1 {
    font-size: 36px;
  }

  .currentTemperature {
    font-size: 50px;
  }

  .currentDescription {
    font-size: 14px;
  }
  
  .currentWeather__image {
    height: 3rem;
  }
  .forecastDate {
    font-size: 14px;
    }
}